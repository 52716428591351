import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/fr"
import ContactPageTemplate from "../../templates/contact-page"

const ContactPageFrench = props => {
  const { title, subtitle } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title} description={subtitle}>
      <ContactPageTemplate {...props} />
    </Layout>
  )
}

export default ContactPageFrench

export const pageQuery = graphql`
  query ContactPageFrench {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/contact/index.fr.md/" }
    ) {
      frontmatter {
        ...ContactPage
      }
    }
  }
`
